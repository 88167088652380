const FrenchContent: React.FC = () => (
  <>
    <p>
      Les termes décrits ci-dessous s'appliquent aux services de déménagement pour lesquels vous vous apprêtez à soumettre une demande via MovingWaldo. Bien que MovingWaldo ne soit pas une entreprise de déménagement, et que les services de déménagement seront complétés par une entreprise indépendante que vous avez choisie sur notre plateforme, nous nous occupons de l'aspect transactionnel de votre déménagement et nous voulons nous assurer que vous comprenez les termes de services dans lesquels vous vous engagez.
    </p>

    <p>
      Lorsque l'entreprise de déménagement que vous avez sélectionnée aura révisé et accepté votre demande, MovingWaldo vous fera parvenir un Estimé de déménagement final qui comprendra les termes de services mentionnés ci. Vous devrez y apposer votre signature électronique, et placer un acompte de 100$ afin de confirmer votre réservation. Si vous avez des questions sur les termes de services, veuillez communiquer avec le service à la clientèle de MovingWaldo.
    </p>

    <h2>Prix final</h2>

    <p>
      Le prix indiqué dans la présente Estimation de déménagement est susceptible d'être modifié le jour du déménagement. Le jour du déménagement, le représentant de l'entreprise de déménagement procédera à une vérification approfondie des informations fournies par le client, notamment, mais sans s'y limiter, la quantité et la nature des objets à déménager, l'état de ces objets et l'état des lieux en général. À la suite de cette vérification, le matériel et les équipements nécessaires à l'exécution des services de déménagement sont susceptibles d'être ajustés.
    </p>

    <p>
      De même, l'estimation donnée pour le temps nécessaire au déménagement dans cette Estimation de déménagement n'est pas définitive et peut différer du temps final nécessaire à la réalisation du déménagement, notamment, mais non exclusivement, en raison de la configuration des lieux de départ et d'arrivée ou de tout impondérable indépendant de la volonté de l'entreprise de déménagement. Si l'entreprise de déménagement n'est pas en mesure de faire entrer tous les articles du client dans le camion de déménagement, des délais supplémentaires seront nécessaires pour des trajets supplémentaires.
    </p>

    <p>
      Le prix final sera confirmé au client une fois le déménagement terminé.
    </p>

    <h2>Paiement et dépôt</h2>

    <p>
      Un dépôt de 100 $ sera débité de votre carte de crédit au moment de l'acceptation de la présente Estimation de déménagement. Le solde restant sera débité une fois le déménagement terminé.
    </p>

    <h2>Annulation</h2>

    <p>
      Le client peut à tout moment annuler le déménagement prévu dans l'Estimation de déménagement dans les dix (10) jours suivant l'acceptation de l'Estimation de déménagement, mais au plus tard 72 heures avant la date prévue du déménagement. Cette annulation doit être faite par écrit à MovingWaldo. MovingWaldo confirmera par écrit à l'adresse courriel du client l'annulation du déménagement.
    </p>

    <p>
      Après dix (10) jours suivant l'acceptation de l'Estimation de déménagement, mais au plus tard 72 heures avant la date prévue du déménagement, le client peut annuler le déménagement, mais des frais d'annulation de 100 $ seront applicables et déduits de la carte de crédit du client.
      Si l'annulation demandée par le client a lieu moins de 72 heures avant le déménagement prévu, le client devra payer un minimum de 3 heures de frais d'annulation.
    </p>

    <h2>Assurance</h2>

    <p>
      L'entreprise de déménagement qui effectue le déménagement sera assurée pour la responsabilité civile et les dommages matériels. La politique exacte de dédommagement en cas de réclamation varie d'une compagnie à l'autre, veuillez-vous en informer auprès de votre conseiller MovingWaldo ou directement auprès de l'entreprise de déménagement.
    </p>

    <p>
      Si aucune protection supplémentaire sur les biens n'est achetée par le client, la protection de base est celle prescrite par la loi. Cette protection paie 60 cents par livre par item endommagé lors du transport. Cela signifie que vous recevrez 60 cents par livre pour le poids de l'article endommagé, quelle que soit la valeur réelle de l'article. Cette protection ne couvre pas les dommages ayant lieu à l'intérieur de la résidence.
    </p>

    <h2>Responsabilité de l'entreprise de déménagement</h2>

    <p>
      L'entreprise de déménagement est la seule responsable de toute perte ou de tout dommage causé aux objets du client, sauf dans les cas prévus ci-après.
    </p>

    <p>
      L'entreprise de déménagement n'est pas responsable des dommages préexistants sur les objets du client ou de toute situation qui pourrait faciliter les dommages aux objets et qui n'a pas été signalée avant le début du déménagement. L'entreprise de déménagement ne peut être tenue responsable de toute perte, de tout dommage ou de tout retard causé par un cas de force majeure, par l'autorité de la loi ou par un acte ou un manquement du client.
    </p>

    <h2>Objets de valeurs extraordinaires</h2>

    <p>
      La compagnie de déménagement ne se porte pas et n'est pas responsable de quelque manière que ce soit des documents, des espèces ou de tout objet de valeur extraordinaire qui n'est pas spécifiquement déclaré dans l'Estimation de déménagement et dont la valeur est stipulée.
    </p>

    <h2>Articles dangereux</h2>

    <p>
      L'entreprise de déménagement peut, à sa discrétion, refuser de transporter tout article pouvant être considéré comme dangereux dans des circonstances raisonnables, notamment, mais sans s'y limiter, tout article potentiellement inflammable, explosif ou contenant des substances toxiques ou dangereuses pour l'environnement ou les personnes. La présence de tout article potentiellement dangereux doit être explicitement déclarée par le client dans l'Estimation de déménagement.
    </p>

    <h2>Défaut de réception des articles</h2>

    <p>
      Si le client refuse de recevoir tout ou partie des objets à déménager, l'entreprise de déménagement peut, après avoir envoyé un avis au client, procéder à la vente des biens et conserver l'intégralité du bénéfice de la vente. Tous les frais engagés pour la conservation des objets du client seront facturés à ce dernier.
    </p>

    <h2>Modification</h2>

    <p>
      Toute modification, addition ou suppression dans la présente Estimation de déménagement qui serait faite sans l'approbation écrite des deux parties sera sans effet.
    </p>
  </>
)

export default FrenchContent
