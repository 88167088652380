import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { isBrowser } from 'react-device-detect'
import FilledStar from '@mui/icons-material/Star'
import UnfilledStar from '@mui/icons-material/StarBorder'

import { mergeClassName } from '../../../../utils/mergeClassName'
import Alert from '../../../Alert'

type StarsRatingProps = {
  name: string
  max?: number
  small?: boolean
  className?: string
}

const StarsRating: React.FC<StarsRatingProps> = ({
  name,
  max = 5,
  small = false,
  className,
}) => {
  const value = useWatch({ name })
  const { setValue, formState: { errors } } = useFormContext()
  const formErrors = errors[name]
  const [hover, setHover] = useState<number | undefined>()

  const onClick = (index: number) => {
    const newValue = value === index ? undefined : index
    setValue(name, newValue, { shouldValidate: true })
    setHover(undefined)
  }

  const onMouseEnter = (index: number) => {
    setHover(index)
  }

  const onMouseLeave = () => {
    setHover(undefined)
  }

  return (
    <>
      <div>
        <div
          className={mergeClassName(
            'inline-flex items-center justify-center gap-x-2 text-yellow-500 child:!text-[48px] cursor-pointer',
            small && 'child:!text-[32px]',
            className,
          )}
          onMouseLeave={isBrowser ? onMouseLeave : undefined}
        >
          { Array.from({ length: max }).map((_, index) => {
            const key = index + 1
            const filled = !!(value && value >= key) || !!(hover && hover >= key)
            const Icon = filled ? FilledStar : UnfilledStar

            return (
              <Icon
                key={key}
                onClick={() => { onClick(key) }}
                onMouseEnter={isBrowser ? () => { onMouseEnter(key) } : undefined}
              />
            )
          }) }
        </div>
      </div>
      { typeof formErrors?.message === 'string' && (
        <div className="mt-2 inline-flex lg:mt-4">
          <Alert>
            { formErrors.message }
          </Alert>
        </div>
      ) }
    </>
  )
}

export default StarsRating
