import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import {
  Modal,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../../Modal'
import StairsDefinitionList from './StairsDefinitionList'

const SESSION_STORAGE_KEY = 'stairs-warning-dismissed'

type StairsWarningModalProps = {
  hasManyStairs: boolean
}

const StairsWarningModal: React.FC<StairsWarningModalProps> = ({
  hasManyStairs,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const isDismissed = () => !!sessionStorage.getItem(SESSION_STORAGE_KEY)

  useEffect(() => {
    if (!hasManyStairs || isDismissed()) {
      return
    }
    setOpen(true)
  }, [hasManyStairs])

  const onConfirm = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, '1')
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      style={{ maxWidth: 600 }}
    >

      <div className="flex w-full">

        <div className="ml-[25px] mr-[-5px] mt-[7px]">
          <InfoIcon className="shrink-0 !text-[30px] text-sky-600" />
        </div>

        <div className="grid w-full gap-4">

          <ModalHeader>
            <ModalTitle
              className="!text-2xl !font-semibold !leading-none !tracking-tight"
            >
              { t('forms.fields.howManyStairs.highStairsWarning.title') }
            </ModalTitle>
          </ModalHeader>

          <ModalDescription>
            <div className="prose max-w-none font-body text-base">
              <div className="pb-3 text-black">
                { t('forms.fields.howManyStairs.highStairsWarning.content') }
              </div>
              <div className="text-neutral-500">
                <StairsDefinitionList />
              </div>
            </div>

          </ModalDescription>
          <ModalFooter>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={onConfirm}
              >
                { t('actions.gotIt') }
              </Button>
            </div>
          </ModalFooter>

        </div>
      </div>
    </Modal>
  )
}

export default StairsWarningModal
