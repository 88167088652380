import { useSelector, useDispatch } from 'react-redux'

import * as selectors from './quotes.selectors'
import * as actions from './quotes.actions'
import { type Quote } from './quotes.models'
import { QuotesView } from './quotes.types'
import { useMove } from '../../moves'
import bestViewHandler from './viewHandlers/best.viewHandler'
import cheapestViewHandler from './viewHandlers/cheapest.viewHandler'
import bestRatedViewHandler from './viewHandlers/bestRated.viewHandler'
import {
  getCheaperQuoteDate,
  getCheaperQuotePrice,
} from './utils/quotes.utils'

const viewHandlerFactory = (view: QuotesView) => {
  switch (view) {
    case QuotesView.best:
      return bestViewHandler
    case QuotesView.bestRated:
      return bestRatedViewHandler
    case QuotesView.cheapest:
    default:
      return cheapestViewHandler
  }
}

/**
 * get current quotes view
 */
export const useQuotesView = () => {
  return useSelector(selectors.quotesView) ?? QuotesView.cheapest
}

/**
 * change quotes view
 */
export const useChangeQuotesViewAction = () => {
  const dispatch = useDispatch()
  return (quotesView: QuotesView) => {
    dispatch(actions.setQuotesView(quotesView))
  }
}

/**
 * returns sorted quotes
 */
export const useSortedQuote = (quotes: Quote[], view?: QuotesView) => {
  return useQuotesSorter()(quotes, view)
}

/**
 * returns a function to filter the quotes
 */
export const useQuotesSorter = () => {
  const savedView = useQuotesView()
  return (quotes: Quote[], view?: QuotesView) => {
    return viewHandlerFactory(view ?? savedView).sort([...quotes])
  }
}

/**
 * returns constraints to display based on filter applied
 */
export const useQuoteConstraints = (quote: Quote) => {
  const savedView = useQuotesView()
  return viewHandlerFactory(savedView).getConstraints?.(quote) ?? quote.constraints
}

/**
 * returns a cheaper alternative date available for a quote (if available)
 */
export const useCheaperDate = (quote: Quote) => {
  const { data: move } = useMove()
  return getCheaperQuoteDate(quote, move)
}

/**
 * returns a cheaper price for a quote (if available)
 */
export const useCheaperPrice = (quote: Quote) => {
  return getCheaperQuotePrice(quote)
}
