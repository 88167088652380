import { type Quote } from '../quotes.models'
import { type QuotesViewHandler, QuoteConstraint } from '../quotes.types'
import * as sortQuotes from '../utils/sort.utils'

const sort = (quotes: Quote[]) => {
  quotes = sortQuotes.byRating(quotes)
  quotes = sortQuotes.byConstraints(quotes, (quote: Quote) => getConstraints(quote, true))

  return quotes
}

const getConstraints = (quote: Quote, sorting = false) => {
  if (!quote.constraints) {
    return []
  }
  const ignoreConstraints = sorting
    ? [
        QuoteConstraint.unavailablePreferredTime,
      ]
    : []

  return quote.constraints.filter(constraint => !ignoreConstraints.includes(constraint))
}

const viewHandler: QuotesViewHandler = {
  sort,
  getConstraints,
}

export default viewHandler
