import { useTranslation } from 'react-i18next'

const StairsDefinitionList: React.FC = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>
        { t('forms.fields.howManyStairs.tooltip.threeStepsOrMore') }
      </li>
      <li>
        { t('forms.fields.howManyStairs.tooltip.connectTwoLevels') }
      </li>
      <li>
        { t('forms.fields.howManyStairs.tooltip.ignoreInterruptions') }
      </li>
      <li>
        { t('forms.fields.howManyStairs.tooltip.excludeBasement') }
      </li>
    </ul>
  )
}

export default StairsDefinitionList
